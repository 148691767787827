import { PropTypes } from 'prop-types';
import React from 'react'

const Intro = ({ title, children, showTitle }) => {
    return (
        <div className="container mb-8 text-center sm:-mt-8 md:-mt-16 md:mb-section">
            <h1 className={showTitle ? '' : 'sr-only'}>{ title }</h1>

            <div className="max-w-2xl mx-auto">
                { children }
            </div>
        </div>
    )
}

Intro.defaultProps = {
    showTitle: false
  };

Intro.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    showTitle: PropTypes.bool,
};

export default Intro;
