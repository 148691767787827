import Circle from './Circle';
import Headphones from '../assets/svg/headphones.svg';
import Laptop from '../assets/svg/laptop.svg';
import React from 'react'
import { useIntl } from 'gatsby-plugin-intl';
import { PropTypes } from 'prop-types';

const PinkCircle = ({ showBadge }) => {
    const intl = useIntl();

    return (
        <Circle
            number="2"
            showBadge={showBadge}
            title={intl.formatMessage({
            id: "home_devices_listen_title",
            defaultMessage: "2de toestel met hoofdtelefoon"})}
            color="pink">
                <div className="flex items-center justify-between">
                    <Laptop aria-hidden="true" />

                    <span className="block mx-3" aria-hidden="true">+</span>

                    <Headphones aria-hidden="true" />
                </div>
        </Circle>
    )
}

PinkCircle.defaultProps = {
    showBadge: false
}

PinkCircle.propTypes = {
    showBadge: PropTypes.bool
}

export default PinkCircle
