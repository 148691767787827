import "../scss/app.scss";

import Footer from "./Footer";
import Header from "./Header";
import { IntlContextProvider } from 'gatsby-plugin-intl';
import PropTypes from "prop-types";
import React from "react";
import { isVoid } from "../helpers/is-void";

function Layout({ children }) {
  return (
    <IntlContextProvider>
      <div className="flex flex-col min-h-screen overflow-hidden">
        <Header />

        <main className="flex-1 w-full mx-auto">
          {children}
        </main>

        <Footer />

        <script src="https://cookie.vrt.be/vrt-cookie-consent.js" onLoad={!isVoid(typeof window) && window.initializeCookies()} defer async key="cookie"></script>
        <script type="text/javascript">_satellite.pageBottom()</script>
      </div>
    </IntlContextProvider>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
