import React, {useEffect, useState} from "react";
import { graphql, useStaticQuery } from "gatsby";

import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import { isVoid } from "../helpers/is-void";

function Analytics({ title }) {
    const [trackPage, setTrackPage] = useState(false);

    useEffect(() => {
        setTrackPage(true);
    }, [])

    const { site } = useStaticQuery(graphql`
    query AnalyticsQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

    return (
        <>
            <Helmet>
                <script type="text/javascript">
                    {`
                        digitalData = {
                            page: {
                                page_uri: "${!isVoid(typeof window) && window.location.pathname}",
                                brand_media: "radio 2",
                                brand_technology: "het warmste koor",
                                channel: "web",
                                page_domain: "${!isVoid(typeof window) && window.location.hostname}",
                                page_url: "${!isVoid(typeof window) && window.location.href}",
                                page_title: "${title} | ${site.siteMetadata.title}",
                                page_language: "nl",
                                environment: "${process.env.GATSBY_APP_ENV}",
                                page_technology: "single page",
                                trackPageView: "${trackPage}"
                            },
                            user: {},
                            session: {},
                            event: {},
                        };
                    `}
                </script>

                <script src="https://assets.adobedtm.com/launch-EN7e1ba6190f45421f81025c684b630cc1.min.js"></script>
            </Helmet>
        </>
    );
}

Analytics.propTypes = {
    title: PropTypes.string.isRequired,
};

export default Analytics;
