import React, {useEffect, useState} from "react";

import { FormattedMessage } from 'gatsby-plugin-intl';
import LeftBottom from '../assets/svg/left-bottom-purple-green.svg';
import { PropTypes } from 'prop-types';
import RightPink from '../assets/svg/right-pink.svg';

const FooterLink = ({ children, to }) => (
    <li className="my-2 mr-8 no-underline md:mr-6 md:my-1">
        <a href={to} target="_blank" rel="noopener noreferrer" className="no-underline hover:underline">
            { children }
        </a>
    </li>
)

function Footer() {
    const [date, setDate] = useState();

    useEffect(() => {
        setDate(new Date().getFullYear());
    }, []);

    return (
        <div>
            <div className="relative -mt-32 z-negative md:-mt-40">
                <LeftBottom className="w-40 -mb-16 sm:w-56 xl:w-64 lg:-mb-20" aria-hidden="true" />

                <RightPink className="absolute bottom-0 right-0 hidden w-12 mb-32 md:w-20 sm:block xl:w-32" aria-hidden="true" />
            </div>

            <footer className="relative z-10 text-sm text-lightpink bg-red">
                <nav className="flex px-4 py-3 md:px-8">
                    <ul className="flex flex-wrap items-center">
                        <FooterLink>
                            <FormattedMessage
                            id="footer_vrt"
                            defaultMessage="VRT @ " />
                            { date }
                        </FooterLink>

                        <FooterLink to="https://jobs.vrt.be/nl/werken-bij-vrt">
                            <FormattedMessage
                            id="footer_jobs"
                            defaultMessage="Jobs" />
                        </FooterLink>

                        <FooterLink to="https://www.vrt.be/nl/info/gebruiksvoorwaarden/">
                            <FormattedMessage
                            id="footer_conditions"
                            defaultMessage="Gebruiksvoorwaarden" />
                        </FooterLink>

                        <FooterLink to="https://www.vrt.be/nl/info/mijn-privacy/">
                            <FormattedMessage
                            id="footer_privacy"
                            defaultMessage="Privacy" />
                        </FooterLink>

                        <FooterLink to="https://www.vrt.be/heb-je-een-vraag/s/">
                            <FormattedMessage
                            id="footer_faq"
                            defaultMessage="Heb je een vraag" />
                        </FooterLink>

                        <FooterLink to="https://www.vrt.be/nl/contact/">
                            <FormattedMessage
                            id="footer_contact"
                            defaultMessage="Contact" />
                        </FooterLink>

                        <FooterLink to="https://www.vrt.be/nl/info/bezoek-de-vrt/">
                            <FormattedMessage
                            id="footer_visit"
                            defaultMessage="Bezoek de VRT" />
                        </FooterLink>

                        <FooterLink to="https://www.vrt.be/nl/info/responsible-disclosure-policy/">
                            <FormattedMessage
                            id="footer_disclosure"
                            defaultMessage="Responsible Disclosure Policy" />
                        </FooterLink>

                        <li className="my-2 mr-8 no-underline md:mr-6 md:my-1" id="privacyLinks"></li>
                    </ul>
                </nav>
            </footer>
        </div>
    );
}

FooterLink.propTypes = {
    children: PropTypes.node,
    to: PropTypes.string,
};

export default Footer;
