import React, { useState } from 'react'

import Play from '../assets/svg/play.svg'
import { PropTypes } from 'prop-types';
import ReactPlayer from 'react-player/youtube'

const Video = ({ url, ariaLabel, thumb, buttonColor }) => {
    const [isPlaying, setIsPlaying] = useState(false);

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();
            setIsPlaying(true);
        }
    }

    return (
        <div className={`w-full video ${buttonColor}`}>
            <ReactPlayer
                url={url}
                controls={true}
                light={!isPlaying && thumb ? require(`../assets/images/${thumb}`) : false}
                playIcon={<Play aria-hidden className="play" />}
                playing={isPlaying}
                volume={1}
                pip={true}
                width='100%'
                height='100%'
                className='player'
                role="button"
                aria-label={ariaLabel}
                onClick={() => setIsPlaying(true)}
                onKeyDown={e => handleKeyDown(e)}
            />
        </div>
    )
}

Video.defaultProps = {
    ariaLabel: 'Speel de video af!',
    buttonColor: 'text-yellow',
};

Video.propTypes = {
    url: PropTypes.string.isRequired,
    ariaLabel: PropTypes.string,
    thumb: PropTypes.string,
    buttonColor: PropTypes.string,
};

export default Video;