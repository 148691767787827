import Circle from './Circle';
import Mobile from '../assets/svg/mobile.svg';
import React from 'react'
import Tablet from '../assets/svg/tablet.svg';
import { useIntl } from 'gatsby-plugin-intl';
import { PropTypes } from 'prop-types';

const BlueCircle = ({ showBadge, number }) => {
    const intl = useIntl();

    return (
        <Circle
            number={number}
            showBadge={showBadge}
            title={intl.formatMessage({
            id: "home_devices_record_title",
            defaultMessage: "GSM of Tablet met camera"})}
            color="blue">
                <div className="flex items-center justify-between -mr-3">
                    <Mobile aria-hidden="true" />

                    <span className="block mx-3 text-xs" aria-hidden="true" >OF</span>

                    <Tablet aria-hidden="true" />
                </div>
        </Circle>
    )
}

BlueCircle.defaultProps = {
    showBadge: false,
    number: '1'
}

BlueCircle.propTypes = {
    showBadge: PropTypes.bool,
    number: PropTypes.string
}

export default BlueCircle;
