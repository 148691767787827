import { Link } from 'gatsby';
import { PropTypes } from 'prop-types';
import React from 'react'

const Button = ({ children, to }) => {
    return (
        <Link to={to} className="block w-full p-4 mx-auto text-base font-bold text-center text-white no-underline transition-colors duration-200 rounded-lg shadow-md md:text-lg bg-red focus:bg-purple hover:bg-purple max-w-mobile-button md:max-w-desktop-button">
            { children }
        </Link>
    )
}

Button.propTypes = {
    to: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
};

export default Button
