import { PropTypes } from 'prop-types';
import React from 'react'

const Circle = ({ title, number, showBadge, color, children }) => {
    return (
        <div className={`bg-${color} rounded-full w-lg-circle h-lg-circle lg:w-md-circle lg:h-md-circle xl:w-lg-circle xl:h-lg-circle flex flex-shrink-0 flex-col justify-between items-center text-center text-white font-bold transform ${showBadge ? 'scale-75 sm:scale-100' : 'scale-100'}`}>
            {
                number &&
                    <span className={`block ${showBadge ? `bg-yellow w-16 h-16 flex justify-center items-center rounded-full self-end mr-2 -mt-6 text-lx` : 'pt-4 text-lg'}`}>
                        {number}

                        {
                            !showBadge && '.'
                        }
                    </span>
            }

            {children}

            {
                title &&
                    <span className="block pb-6 mx-6 text-base leading-tight">{title}</span>
            }
        </div>
    )
}

Circle.propTypes = {
    title: PropTypes.string,
    number: PropTypes.string,
    color: PropTypes.string.isRequired,
    children: PropTypes.node,
    showBadge: PropTypes.bool
}

export default Circle
