import LeftPink from '../assets/svg/left-pink.svg';
import Logo from '../assets/svg/hwk_logo.svg';
import React from "react";
import RightTop from '../assets/svg/right-top-blue-yellow.svg';
import { Link } from 'gatsby';
import { ROUTES } from '../pages/index';

function Header() {
  return (
    <header className="flex flex-col">
      <RightTop className="self-end flex-shrink-0 h-auto w-sm-shape sm:w-md-shape lg:w-lg-shape" aria-hidden="true" />
      <div className="container flex items-center justify-center -mt-4 -mb-12 sm:-mb-24 md:-mb-32 lg:-mb-56 sm:-mt-12 md:-mt-16 lg:-mt-32 ">
        <Link to={ROUTES.HOME}>
          <Logo className="h-auto w-sm-logo md:w-md-logo lg:w-lg-logo" aria-hidden="true" />
        </Link>
      </div>
      <LeftPink className="flex-shrink-0 w-6 h-auto sm:w-12 md:w-xs-shape lg:w-sx-shape" aria-hidden="true" />
    </header>
  );
}

export default Header;
