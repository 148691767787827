import {
    FacebookIcon,
    FacebookShareButton,
    TwitterIcon,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton,
} from "react-share";
import React, { useState } from 'react';

import { FormattedMessage } from 'gatsby-plugin-intl';
import Link from '../assets/svg/link.svg';
import { PropTypes } from 'prop-types';

const CopyClipboard = ({ url }) => {
    const [isCopied, setIsCopied] = useState(false)

    const copy = async () => {
        try {
            await navigator.clipboard.writeText(url);
            setIsCopied(true);

            setTimeout(() => {
                setIsCopied(false);
            }, 1000);
        } catch (err) {
            setIsCopied(false);

            alert(`Your browser does not support the Clipboard API. You can manually copy this link: ${url}`);
        }
    }

    return (
        <button className={`mx-2 ${isCopied ? 'text-green' : 'text-gray-dark'}`} onClick={copy}>
            <Link />
        </button>
    )
}

const Social = ({ link }) => {
    const [url] = useState(link);

    return (
        <section className="relative z-10 flex flex-col items-center justify-center mb-32 sm:mb-section">
            <h2>
                <FormattedMessage
                id="general_share_initiative"
                defaultMessage="Deel deze Warmste Week-actie" />
            </h2>

            <div>
                <FacebookShareButton url={url} className="mx-2">
                    <FacebookIcon size={55} round={true} />
                </FacebookShareButton>

                <TwitterShareButton url={url} className="mx-2">
                    <TwitterIcon size={55} round={true} />
                </TwitterShareButton>

                <WhatsappShareButton url={url} className="mx-2">
                    <WhatsappIcon size={55} round={true} />
                </WhatsappShareButton>

                <CopyClipboard url={url}/>
            </div>
        </section>
    )
}

Social.defaultProps = {
    link: "hetwarmstekoor.radio2.be"
}

Social.propTypes = {
    link: PropTypes.string.isRequired
}

CopyClipboard.propTypes = {
    url: PropTypes.string.isRequired
}

export default Social
